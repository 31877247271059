import React from "react"
import { motion } from "framer-motion"

//Utils
import { variants } from "../utils/framerUtils"

const ProjectsMenu = ({
  view,
  setView,
  types,
  projectsFilter,
  setProjectsFilter,
}) => {
  return (
    <motion.div
      variants={variants.fadeUpandInStaggerChildren}
      className="font-light md:text-desktop-medium text-mobile-medium leading-medium text-love-grey"
    >
      <motion.div variants={variants.fadeUpandIn}>
        <button
          className={`hover:text-black mr-lead-1 ${
            view === "grid" && "text-black"
          }`}
          onClick={() => setView("grid")}
        >
          GRID
        </button>
        <button
          className={`hover:text-black mr-lead-1 ${
            view === "list" && "text-black"
          }`}
          onClick={() => setView("list")}
        >
          LIST
        </button>
        <motion.div
          variants={variants.fadeUpandInStaggerChildren}
          className="flex flex-col mt-lead-1"
        >
          <motion.button
            variants={variants.fadeUpandIn}
            onClick={() => setProjectsFilter(["all", ...types])}
            className={`uppercase w-max hover:text-black ${
              projectsFilter.length > 1 && "text-black"
            }`}
          >
            ALL
          </motion.button>
          {types.map(type => {
            return (
              <motion.button
                variants={variants.fadeUpandIn}
                key={type}
                onClick={() => setProjectsFilter([type])}
                className={`uppercase w-max hover:text-black ${
                  projectsFilter[0] === type && "text-black"
                }`}
              >
                {type}
              </motion.button>
            )
          })}
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default ProjectsMenu
