import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { AnimatePresence, motion } from "framer-motion"

//utils
import { variants } from "../utils/framerUtils"

//components
import RightArrow from "./rightArrow"
const ImageGridCard = ({ project, setSelectedProject, index }) => {
  const [hovered, setHovered] = useState(false)
  return (
    <motion.div
      variants={variants.fadeUpandIn}
      layout="position"
      className={`relative pb-lead-2 mb-lead-2 h-max col-span-1 md:col-span-3 md:hover:grayscale hover:cursor-pointer ${
        index % 2 === 0 ? "col-start-1" : "md:col-start-5"
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => setSelectedProject(project)}
    >
      <GatsbyImage
        image={project.featureImage.asset.gatsbyImageData}
        alt={project.featureImage.altText || "A LoveArt Project Feature Image"}
      />
      <h3 className="font-light md:text-desktop-medium text-mobile-medium my-[0.65rem]">
        <span>{project.title}</span>
        {/* <span className="text-love-grey pl-[0.6rem]">
          {project.projectYear}
        </span> */}
      </h3>
      <p className="uppercase text-love-grey mb-lead-1">
        {project.projectType}
      </p>
      <AnimatePresence>
        {hovered && (
          <motion.div
            key={project.title}
            className="absolute bottom-0 overflow-hidden"
          >
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
              className={`flex items-center font-light  md:text-desktop-medium text-mobile-medium`}
            >
              <span className="mr-[0.65rem]">View</span>
              <RightArrow />
            </motion.p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default ImageGridCard
