import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

// Potential bug: wrapping buttons in buttons with different onCLick events. Seems ok for now but if problem investigate.

//utils
import { variants } from "../utils/framerUtils"

const MobileProjectsMenu = ({
  view,
  setView,
  types,
  projectsFilter,
  setProjectsFilter,
}) => {
  const [menuState, setMenuState] = useState({
    filterOpen: false,
    viewOpen: false,
  })
  return (
    <motion.nav
      variants={variants.fadeIn}
      className="flex font-light text-mobile-medium px-lead-1 pb-lead-1"
    >
      <div className="w-1/2">
        <button
          onClick={() =>
            setMenuState(prev => {
              return {
                filterOpen: prev.filterOpen ? false : true,
                viewOpen: false,
              }
            })
          }
        >
          <div className="flex items-center">
            <span className="mr-lead-1">FILTER</span>
            <DownArrow open={menuState.filterOpen} />
          </div>
          <AnimatePresence>
            {menuState.filterOpen && (
              <ExpandableDiv>
                <button
                  onClick={() => setProjectsFilter(["all", ...types])}
                  className={`uppercase w-max mt-lead-1 ${
                    projectsFilter.length > 1 && "text-black"
                  }`}
                >
                  All
                </button>
                {types.map(type => {
                  return (
                    <button
                      key={type}
                      onClick={() => setProjectsFilter([type])}
                      className={`uppercase w-max ${
                        projectsFilter[0] === type && "text-black"
                      }`}
                    >
                      {type}
                    </button>
                  )
                })}
              </ExpandableDiv>
            )}
          </AnimatePresence>
        </button>
      </div>
      <div className="w-1/2">
        <button
          onClick={() =>
            setMenuState(prev => {
              return {
                viewOpen: prev.viewOpen ? false : true,
                filterOpen: false,
              }
            })
          }
        >
          <div className="flex items-center">
            <span className="mr-lead-1">VIEW</span>
            <DownArrow open={menuState.viewOpen} />
          </div>
          <AnimatePresence>
            {menuState.viewOpen && (
              <ExpandableDiv>
                <button
                  className={`mt-lead-1 ${view === "grid" && "text-black"}`}
                  onClick={() => setView("grid")}
                >
                  GRID
                </button>
                <button
                  className={`${view === "list" && "text-black"}`}
                  onClick={() => setView("list")}
                >
                  LIST
                </button>
              </ExpandableDiv>
            )}
          </AnimatePresence>
        </button>
      </div>
    </motion.nav>
  )
}

export default MobileProjectsMenu

const arrowVariants = {
  open: {
    rotate: 180,
  },
  closed: {
    rotate: 0,
  },
}

const DownArrow = ({ open }) => (
  <motion.svg
    initial="closed"
    animate={open ? "open" : "closed"}
    variants={arrowVariants}
    fill="none"
    height="8"
    viewBox="0 0 12 8"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="#000">
      <path d="m1.15824 1.10348 5.58166 5.58166" />
      <path d="m6.03121 6.68551 5.58169-5.58167" />
    </g>
  </motion.svg>
)

const ExpandableDiv = ({ children }) => {
  return (
    <motion.div
      initial={{
        height: 0,
      }}
      animate={{
        height: "auto",
      }}
      exit={{
        height: 0,
      }}
      className="flex flex-col items-start overflow-y-hidden text-love-grey"
    >
      {children}
    </motion.div>
  )
}
