import React from "react"
import { motion } from "framer-motion"

//utils
import { variants } from "../utils/framerUtils"

const PageIntroText = ({ children }) => {
  return (
    <motion.p
      variants={variants.fadeUpandInStaggerChildren}
      className="font-light text-mobile-medium md:my-lead-0 my-lead-8 md:text-desktop-medium leading-medium text-love-grey md:h-lead-15"
    >
      {children}
    </motion.p>
  )
}

export default PageIntroText
