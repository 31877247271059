import React, { useState } from "react"
import { graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { useMediaQuery } from "react-responsive"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

//components
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import ProjectsMenu from "../components/projectsMenu"
import MobileProjectsMenu from "../components/mobileProjectsMenu"
import PageIntroText from "../components/pageIntroText"
import ImageGridCard from "../components/imageGridCard"

//components
import TransitionOverlay from "../components/transitionOverlay"

//utils
import { variants } from "../utils/framerUtils"

/*  TODOS
    - Fix can't split referrer url on page reload
*/

const projectTypes = ["corporate", "residential", "exhibition"]

const Projects = ({ data, location }) => {
  const referrerURL = location?.state?.referrer
  const splitFilteredURL = referrerURL
    ? referrerURL.split("/").filter(item => item)
    : []
  const [hoveredProject, setHoveredProject] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [fromProject, setFromProject] = useState(
    splitFilteredURL.includes("projects") && splitFilteredURL.length > 1
  )
  const [view, setView] = useState("grid")
  const [projectsFilter, setProjectsFilter] = useState([
    "all",
    "corporate",
    "residential",
    "exhibition",
  ])
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  return (
    <Layout footer>
      {(selectedProject || fromProject) && (
        <TransitionOverlay
          from={fromProject}
          selectedItem={selectedProject}
          setFrom={setFromProject}
          navigateTo={`/projects/${selectedProject?.slug.current}`}
        />
      )}
      <Sidebar pageTitle={"PROJECTS"} isProjectsPage={true}>
        {isMobile ? (
          <MobileProjectsMenu
            view={view}
            setView={setView}
            types={projectTypes}
            projectsFilter={projectsFilter}
            setProjectsFilter={setProjectsFilter}
          />
        ) : (
          <ProjectsMenu
            view={view}
            setView={setView}
            types={projectTypes}
            projectsFilter={projectsFilter}
            setProjectsFilter={setProjectsFilter}
          />
        )}
      </Sidebar>
      <motion.div
        variants={variants.fadeIn}
        className="md:col-start-5 md:col-span-7"
      >
        <PageIntroText>{data.sanityProjectsPage.introText}</PageIntroText>
        {/* Projects list and grid */}
        <AnimatePresence mode="wait">
          {view === "list" && (
            <motion.div
              key={"projectsList"}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={variants.wrapperNoStyles}
            >
              <motion.div variants={variants.fadeUpandInStaggerChildren}>
                {data.allSanityProject.nodes
                  .filter(project =>
                    projectsFilter.includes(project.projectType)
                  )
                  .map(project => {
                    return (
                      <motion.div
                        key={project.id}
                        variants={variants.fadeUpandIn}
                        layout="position"
                        // className="border-t-[1px] border-love-grey grid grid-cols-4 md:grid-cols-7 gap-lead-1 py-[15px] hover:text-love-grey cursor-pointer"
                        className="relative grid grid-cols-4 md:grid-cols-7 gap-lead-1 py-[15px] hover:text-love-grey cursor-pointer"
                        onMouseEnter={() => setHoveredProject(project)}
                        onMouseLeave={() => setHoveredProject(null)}
                        onClick={() => setSelectedProject(project)}
                      >
                        <motion.div
                          variants={variants.projectListItemLine}
                          className="absolute origin-left  w-full h-[1px] bg-love-grey top-0"
                        />
                        <span className="col-span-2 uppercase truncate md:col-span-3">
                          {project.title}
                        </span>
                        <span className="uppercase md:col-start-5 md:col-span-1 text-love-grey">
                          {project.projectType}
                        </span>
                        <span className="flex flex-row justify-end uppercase md:col-start-7 md:col-span-1 text-love-grey">
                          {hoveredProject === project && !isMobile ? (
                            <RightArrow />
                          ) : (
                            project.projectYear
                          )}
                        </span>
                      </motion.div>
                    )
                  })}
              </motion.div>
            </motion.div>
          )}
          {view === "grid" && (
            <motion.div
              key={"projectsGrid"}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={variants.fadeUpandInStaggerChildren}
              className="md:grid md:grid-cols-7 gap-lead-1"
            >
              {data.allSanityProject.nodes
                .filter(project => projectsFilter.includes(project.projectType))
                .map((project, index) => {
                  return (
                    <ImageGridCard
                      key={project.id}
                      project={project}
                      index={index}
                      setSelectedProject={setSelectedProject}
                    />
                  )
                })}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      {/* Hovered Project List Image */}
      {!isMobile && hoveredProject && (
        <motion.div
          key={hoveredProject.id}
          className="fixed z-20 left-lead-1 md:top-lead-1 md:left-lead-1 md:w-[calc((100%/12*3)-1.4rem)] md:h-[calc(100vh-2.8rem)] flex flex-col justify-center pointer-events-none"
        >
          <GatsbyImage
            className="shadow-lg"
            image={hoveredProject.featureImage.asset.gatsbyImageData}
            alt="image alt text"
          />
        </motion.div>
      )}
    </Layout>
  )
}

const RightArrow = () => {
  return (
    <span className="flex flex-row">
      <span className="text-black pr-lead-1">VIEW</span>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.335 0.489746L18.6714 8.61715L18.6686 8.62012H18.6719V10.1201H18.6705L10.335 18.2466L9.28785 17.1725L16.5217 10.1201H0.987305V8.62012H16.5257L9.28785 1.56379L10.335 0.489746Z"
          fill="black"
        />
      </svg>
    </span>
  )
}

export default Projects
export const Head = () => <Seo title="Projects" />
//data
export const query = graphql`
  query {
    sanityProjectsPage {
      introText
    }
    allSanityProject(
      sort: { fields: [projectYear, title], order: [DESC, ASC] }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        projectYear
        projectType
        featureImage {
          altText
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
