import React from "react"
import { motion } from "framer-motion"
import { navigate } from "@reach/router"

//Variants

const modalTransition = {
  duration: 0.6,
  ease: [0.85, 0, 0.15, 1],
}

const overlayVariants = {
  offScreen: {
    y: "100%",
    transition: {
      ...modalTransition,
      duration: 1,
    },
  },
  onScreen: {
    y: 0,
    transition: {
      ...modalTransition,
      duration: 1,
    },
  },
}

const TransitionOverlay = ({ selectedItem, from, setFrom, navigateTo }) => {
  return (
    <div className="fixed top-0 left-0 z-50 w-full h-screen overflow-hidden pointer-events-none">
      <motion.div
        initial={from ? "onScreen" : "offScreen"}
        animate={from ? "offScreen" : "onScreen"}
        variants={overlayVariants}
        onAnimationComplete={animation => {
          //TODO Only navigate if selectedItem
          if (animation === "offScreen" && from) {
            setFrom(false)
          }
          console.log("animation complete", animation)
          if (animation === "onScreen" && selectedItem) {
            // console.log(selectedItem.slug.current)
            navigate(navigateTo)
          }
        }}
        className="w-full h-full bg-white pointer-events-auto shadow-page-shadow"
      ></motion.div>
    </div>
  )
}

export default TransitionOverlay
