import React from "react"

const RightArrow = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.335 0.489746L18.6714 8.61715L18.6686 8.62012H18.6719V10.1201H18.6705L10.335 18.2466L9.28785 17.1725L16.5217 10.1201H0.987305V8.62012H16.5257L9.28785 1.56379L10.335 0.489746Z"
        fill="black"
      />
    </svg>
  )
}

export default RightArrow
